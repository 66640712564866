import React from 'react'
import { SRLWrapper } from "simple-react-lightbox";
import { GatsbyImage,StaticImage } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'
import {Box,Flex,Heading,Text, Center,HStack, SimpleGrid} from '@chakra-ui/react'
//import Player from '../components/player'
import {motion} from 'framer-motion'
import MeinSeo from '../components/seo'

const bildname=[
  {'name':''},
  {'name':''},
  {'name':''},
  {'name':''},
  {'name':''},
  {'name':''},
  {'name':'Bauphase'},
  {'name':'Bauphase'}
]

const options ={
  buttons: {
    showDownloadButton: false
  },
  thumbnails: {
    showThumbnails: true,
    thumbnailsAlignment: 'center',
    thumbnailsContainerBackgroundColor: 'transparent',
    thumbnailsContainerPadding: '0',
    thumbnailsGap: '0 1px',
    thumbnailsIconColor: '#ffffff',
    thumbnailsOpacity: 0.4,
    thumbnailsPosition: 'bottom',
    thumbnailsSize: ['100px', '100px']
  }
}

  function RotesFenster({data}) {

    return (
      <motion.div
      initial={{opacity:0,scale:.7,paddingBottom:"100px"}}
      animate={{opacity:1,scale:1}}
      transition={{duration:.55,delay:.1}}
      >
<Box pb="100px" pt="10">
<HStack mb="15" justifyContent="space-between">



<Box>
<StaticImage
  src="../images/werner/26.jpeg"
  alt="Werner Abbildung"
 
  layout='constrained'
  height={650}
  width={470}
/>
</Box>
<Box>
<StaticImage
  src="../images/werner/10.jpg"
  alt="Werner Abbildung"
  layout='constrained'
  height={650}
  width={470}
/>

</Box>
</HStack>
      <div className="MyComponent">
        <MeinSeo title="Fotos vom Roten Fenster. Der Künstler Karl-Heinz Hartmann" />
        <SRLWrapper options={options}>
        <Heading variant="Titel" mt="6" py="3">Toleranz vor Augen</Heading> 
              <Text variant="solid">Ein Projekt von Karl-Martin Hartmann in der Wernerkapelle in Bacharach <br/> in Zusammenarbeit mit dem Bauverein Wernerkapelle</Text> 
              <Text>
            „Toleranz vor Augen - DAS FENSTER + DAS FORUM war eine Installation in der Wernerkapelle in Bacharach, im UNESCO-Welterbe Oberes-Mittelrheintal. <br/>Im Kontext unseres Engagements der Toleranz mit den Stelen ein weltweites Zeichen zu setzen, war dieses Fenster ein „Ausrufezeichen für eine tolerante Welt zu arbeiten, zu wirken und zu werben (Johannes Gerster),“ an einem auch durch seine Geschichte nicht aufgelöstem Ort - die Wernerkapelle steht in denkwürdigem Zusammenhang mit der Ritualmord-Legende um den 1287 angeblichen von Juden getöteten Knaben Werner.<br/> Das zur Toleranz mahnende Fenster war von 2007 bis 2010 in der Wernerkapelle installiert und wurde 2008 und 2009, im Rahmen der Vortragsreihe zum Thema Toleranz „DAS FORUM“, durch 14 Vorträge zum Wert und der Notwendigkeit der Toleranz für eine Gesellschaft gestärkt. Auf den mittleren Bahnen des Fensters war der Anfang von Heinrich Heines „Rabbi von Bacharach“ zu lesen, als die immer wiederkehrende Aktualität des Themas Toleranz zwischen den Religionen. Am Pfingstmontag 2010 wurde das Fenster dann im Rahmen einer Feier ausgebaut und in den darauf folgenden Tagen die Installation rückgebaut."

            </Text>
            <Text variant='Bild'>Text von Karl-Martin Hartmann</Text>
            {/* <Player url="/audio/rfenster.mp3" /> */}

<SimpleGrid columns={[1,1,2]} gap="9">
<Box>

 
   <Box flex="1" py="3">
   <StaticImage src="../images/peter-keber.jpeg" alt="Peter Keber"   />
   </Box>
   <a rel="noreferrer nopener" target="_blank" href="https://open.spotify.com/show/5cgsw63TlArgSZtSuEyHhK"><Text textAlign={["center","right"]} fontSize="sm" fontWeight="600" mr="1" color="red.400">&rarr; K.O.M PODCAST: Menschen vom Mittelrhein<br/>Interview mit Peter Keber zur Wernerkapelle</Text></a>
   
</Box>
  <Box flex="1">
            <Text variant='solid' >
             Peter Keber, der Gründer des Bauvereins Wernerkapelle,  bietet <span style={{fontWeight:600}}>zwei Führungen zur Geschichte der Wernerkapelle</span> an:<br/><br/>
             Sonntag, den 19. Juni 2022 um 11:00 Uhr<br/>
             Samstag, den 9. Juli 2022 um 11:00 Uhr <br/>
             Anmeldung nicht erforderlich
            </Text>  
   </Box>    
 </SimpleGrid>   
            
        <Flex mt="30px" flexWrap="wrap" flexDirection={["column","column","row"]}>
        {data.images.edges.map((e,index) => {

          return (
             
              <Box position="relative" overflow="hidden" m="1" maxWidth={['100%','100%','45%']} maxHeight={[880,880,760]} cursor="pointer" key={index} >
             <Center>
            <GatsbyImage

              image={e.node.childImageSharp.gatsbyImageData}

             alt={bildname[index]!==undefined?bildname[index].name:""}

            />
            </Center>
         <Box bottom="0" position="absolute" zIndex="34" bg="whiteAlpha.300" px="2"> 
         <Text color="white"  textAlign="left" p="0"  fontSize="sm">{bildname[index]?.name!==undefined?bildname[index].name:""}</Text></Box>
         </Box>
     
          )

        })}
    </Flex>
        </SRLWrapper>

      </div>
      <Text fontSize="xs" >©Fotos von Karl-Heinz-Schleis</Text>
      </Box>
    </motion.div>

    )

      }
  
  export const query = graphql`

  {

    images: allFile(
      filter: { relativeDirectory: { eq: "kapelle" } }
      sort: { order: ASC, fields: base }
    ) {
      edges {

        node {

          base

          id

          relativePath

          childImageSharp {
             gatsbyImageData(
              layout: CONSTRAINED
              placeholder: BLURRED
              transformOptions: {
                cropFocus: ATTENTION
                fit: CONTAIN
                
              }
              
            )
          }

        }

      }

    }
    thumbnails: allFile(
        filter: { relativeDirectory: { eq: "kapelle/thumbnails" } }
        sort: { order: ASC, fields: base }
  
      ) {
  
        edges {
  
          node {
  
            base
  
            id
  
            relativePath
  
            childImageSharp {
  
              gatsbyImageData(
  
                placeholder: BLURRED
  
                layout: CONSTRAINED
  
                width: 120
  
                height: 120
  
              )
  
            }
  
          }
  
        }
  
      }
  
    

  }`


  export default RotesFenster;




